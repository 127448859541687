import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  task: "",
  subTask: "",
};

export const smTaskSubTaskSlice = createSlice({
  name: "smTaskSubTask",
  initialState,
  reducers: {
    setTask: (state, action) => {
      state.task = action.payload.task;
    },
    setSubTask: (state, action) => {
      state.subTask = action.payload.subTask;
    },
    resetTaskSubTask: (state) => {
      state.task = "";
      state.subTask = "";
    },
  },
});

export const { setTask, setSubTask, resetTaskSubTask } =
  smTaskSubTaskSlice.actions;

export default smTaskSubTaskSlice.reducer;
